@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';

.left-side {
  float: left;
}

.right-side {
  float: right;
}

.taxon-details {
  width: unset;
  margin: 0 auto;

  @include media-query(desk) {
    width: $desk-width;
  }

  @include media-query(full-hd) {
    width: 1280px;
  }
}

.taxon-details__information {


  .taxon-details__information__vernacular-names,
  .taxon-details__information__protections,
  .taxon-details__information__synonyms {
    list-style: none;
  }
  .taxon-details__information__vernacular-name {
    display: inline;
  }
  .taxon-details__information__protection {
    padding: 5px 0 0 0;
    white-space: normal;
    &:first-child {
      padding: 10px 0 0 0;
    }
  }
  .taxon-details__information__synonyms {

    .valid-taxa {
      font-weight: bold;
    }

    .valid-taxa:after {
      content: "*";
    }
  }

  .taxon-details {
    &__icon,
    &__textbox {
      vertical-align: middle;
      display: inline-block;
    }

    &__textbox {
      > div {
        border-radius: 4px;
        padding: 4px 16px;
        border: 1px solid;
      }

      > span {
        border-radius: 4px;
        //padding: 5px 5px;
        border: 1px solid;
        line-height: 15px;
      }

      .taxon-details-patrimonial {
        color: inherit;
        border-color: inherit;
        background: $color-taxon-patrimonial;
      }

      .taxon-details-protege {
        color: $color-taxon-protege;
        border-color: $color-taxon-protege;
      }

      .taxon-details-exotique {
        color: inherit;
        border-color: inherit;
        background: $color-taxon-exotique;
      }

      .taxon-details-menace {
        color: inherit;
        border-color: inherit;
        background: $color-taxon-menace;
      }

      .taxon-details-sen {
        color: inherit;
        border-color: inherit;
        background: $color-taxon-sen;
      }
    }
  }
}

.taxon-details__buttons {
  position: absolute;
  top: 15px;
  right: 15px;

  .taxon-details__button--visible-lg {
    @include media-query(lap) {
      display: none;
    }
    @include media-query(palm) {
      display: none;
    }
  }
}

.taxon-details__taxonomy {
  width: 300px;
  text-align: center;

  .taxon-details__taxonomy__types {
    list-style: none;
  }
}

ul.taxon-details__taxonomy__types > li {
    display: inline-block;
}

$LR-colors:   ('EX': #000, 'EW': #411450, 'RE': #5b1764, 'REw': #5b1764, 'CR': #d3050c, 'CR*': #d3050c, 'EN': #fcbd00, 'VU': #ffed00, 'NT': #fbf2cb, 'LC': #74b94c, '#': #d3d4d4, 'DD': #d3d4d4, 'NA': #919291, 'NE': #FFF);

// https://coolors.co/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1
$RAR-colors:  ('#': #d3d4d4, '?': #FFF, 'TC': #277DA1, 'CC': #277DA1, 'C': #577590, 'AC': #4D908E, 'PC': #43AA8B, 'O': #90BE6D, 'AR': #F9C74F, 'R': #F8961E, 'TR': #F3722C, 'RR': #F3722C, 'D': #000000, 'E': #F94144, );

$DETZNIEFF-colors:  ('Det.': #A0BF29, 'Det.sscond.': #4DAE9D, 'Comp.': #4592BC, 'Comp.sscond.': #5C68AD, '#': #593E8F );

$Env-colors:  ('A': #F3722C, 'P': #F9C74F, 'E': #277DA1, '?': #494949 );

$Tend-emojis:  ('?': "\2753" , 'E': "\2b06",  'P': "\2197", 'S': "\2194", 'R': "\2198",  'D': "\2b07" , 'X': "\274c");

$Tend-colors:  ('?': #FFF , 'E': #277DA1,  'P': #277DA1, 'S': #4D908E, 'R': #F8961E,  'D': #F3722C , 'X': #000000);


// https://www.scrivito.com/blog/sass-magic
@function set-button-text-color($color) {
    @if (lightness( $color ) > 40) {
      @return black;
    }
    @else {
      @return white;
    }
}

@function set-button-border-color($color) {
    @if (lightness( $color ) > 60) {
      @return black;
    }
    @else {
      @return white;
    }
}

@mixin status-color($color, $map) {

    $color_: map-get($map, $color);

    background-color: $color_;
    color: set-button-text-color($color_);
    border: 2px solid;
    border-color: set-button-border-color($color_);

    &:hover {
        background-color: rgba($color_, 0.55);
    }
}

@each $key, $color in $LR-colors {

  span[class^="R-LR"][class$='#{$key}']:not(:empty)  {
    @include status-color($key, $LR-colors);
  }

  span[class^="R-LR"][class$='#{$key}']:not(:empty):after  {
    content: "";
  }

}

@each $key, $color in $RAR-colors {

  span[class^="R-Rar"][class$='-#{$key}']:not(:empty),span[class^="R-Rar"][class$='-#{$key}?']:not(:empty)  {
    @include status-color($key, $RAR-colors);
  }

  span[class^="R-Rar"][class$='-#{$key}']:not(:empty):after,span[class^="R-Rar"][class$='-#{$key}?']:not(:empty):after  {
    content: "";
  }

}

@each $key, $color in $DETZNIEFF-colors {

  span[class^="R-DetZNIEFF"][class$='#{$key}']:not(:empty) {

    $color_: map-get($DETZNIEFF-colors, $key);
    color: $color_;
    font-weight: bold;
  }

  span[class^="R-DetZNIEFF"][class$='-#{$key}']:not(:empty):after  {
    content: "";
  }

}

@each $key, $color in $Env-colors {

  span[class^="R-Env"][class$='#{$key}']:not(:empty) {

    $color_: map-get($Env-colors, $key);
    color: $color_;
    font-weight: bold;
  }

  span[class^="R-Env"][class$='#{$key}']:before {
    //content: "\2713 ";
    //https://www.unicode.org/emoji/charts/full-emoji-list.html
    //content: "\1F534 "
  }

}

@each $key, $color in $Tend-colors {

  span[class^="R-Tend"][class$='#{$key}']:not(:empty),span[class^="R-Tend"][class$='#{$key}?']:not(:empty)  {
    @include status-color($key, $Tend-colors);
  }

}

@each $key, $color in $Tend-emojis {

  span[class^="R-Tend"][class$='#{$key}']:not(:empty):before,span[class^="R-Tend"][class$='#{$key}?']:not(:empty):before  {
    $emoji: map-get($Tend-emojis, $key);
    //content: $emoji;
    //font-size: 30px;
    //font-family: Arial;
  }

}


//

span[class^="R-"] {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  clear:none;
}

span[class^="R-LR"]:not(:empty) {
  color: #000;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 3px;
  border: 2px solid #FFF;
}

span[class^="R-LR"]:not(:empty):after {
  //content: "\1f9F0";
  //content: "\274C";
  color: #000000;
}

/////////////////////////////////////////////////

span[class^="R-Rar"]:not(:empty) {
  //color: #494949;
  font-weight: bold;
  border-radius: 50%;
	width: 50px;
	display: inline-block;
	font-stretch: condensed;
	text-align: center;
}

span[class^="R-Rar"]:not(:empty):after {
  //content: "\274C";
  //content: "\1f9F0";
  color: #000000;
}

/////////////////////////////////////////////////

span[class^="R-DetZNIEFF"]:not(:empty):after {
  //content: "\274C";
  //content: "\1f9F0";
  color: #000000;
}

/////////////////////////////////////////////////
/*
span[class^="R-Tend"]:not(:empty) {
  //color: #494949;
  font-weight: bold;
  border-radius: 25%;
  border: 2px solid;
	width: 50px;
	display: inline-block;
	font-stretch: condensed;
	text-align: center;
}*/

/////////////////////////////////////////////////

span[class^="R-Menace"] {
  color: #494949;
  font-weight: bold;
  border-radius: 50%;
  border: 2px solid #494949;
}

span[class^="R-Menace"][class$="-M"] {
  background-color: #F00;
  color: #FFF;
  border-color: #FFF;
}

span[class^="R-Menace"][class$="-Ne"] {
  background-color: #AFAFAF;
  color: #FFF;
  border-color: #FFF;
}
/////////////////////////////////////////////////

span[class^="R-Int_Patri"]:not(:empty) {
  color: #494949;
  font-weight: bold;
  border-radius: 50%;
  border: 2px solid #fff;
	width: 60px;
	display: inline-block;
	font-stretch: condensed;
	text-align: center;
  background-color: #43aa8b;
  color: #fff;
}
