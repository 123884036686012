.study-search-form {
  .study-search-form__query {
    max-width: 800px;
    margin: auto;
  }
}

.study-search-form .align-button {
  align-self: center;
  height: 30px;
}

.study-search-form__toolbar {
  height: 39px;
}
