@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/bourbon.scss';

.field-record-details-container .tabs__links {
  z-index: 1;
  height: 37px;
  border-bottom: 1px solid $grey-300;

  >li {
    vertical-align: middle;
  }
}
.field-record-details-container .tabs__panes {
  height: 100%;
  overflow: auto;
  position: relative;
  @include display(flex);
  @include flex-direction(column);
  @include flex(1);
}
.field-record-details-container .tabs-pane {
  height: 100%;
}
.field-record-details-container .tabs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
.field-record-details-container .tabs-link {
  height: 36px;
  line-height: 36px;
}
.field-record-details-container .tabs::after {
  top: 36px;
}
.field-record-details-container .tabs__indicator {
  top: 35px;
}

.field-record-details-container .tabs-link .mdi{
  font-size: 1.0rem;
  line-height: 36px;
}
.field-record-details-container .tabs--theme-dark {
  &:after {
    background-color: $blue-500;
  }

  .tabs__pagination-left, .tabs__pagination-right {
    color: $blue-500;
  }

  .tabs-link {
    color: $blue-500;
  }
}
