@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';

.settings-form {
  min-width: 600px;

  .item-textarea {
    label {
      transform: scale(0.75);
      transform-origin: bottom left;
      display: block;
      color: $black-2;
    }
    textarea {
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid $black-4;
    }
  }

  @include media-query(palm) {
    min-width: unset;
  }
}
