@import '../base/variables.scss';

.taxon-principal {
  font-weight: bold;
}

.taxon-secondaire {
  font-style: italic;
}

.taxon-protege {
  color: $color-taxon-protege;
}

.select-taxon .statuses,
.select-taxon-selected .statuses {
  margin-left: 10px;
  .badge-taxon-status:not(:first-child) {
    margin-left: 5px;
  }
}

.badge-taxon-status {
  color: #000;
  font-weight: 400;
  padding: 1px 3px 0 3px;
  display: inline-block;
  line-height: normal;
  sup {
    &:not(:first-of-type) {
      margin-left: 4px;
    }
  }
}

.shu-select-taxon {
  .badge-taxon-status {
    sup {
      &:not(:first-of-type) {
        margin-left: 1px;
      }
    }
  }
}

.badge-taxon-status-PR_s {
  color: #FFF !important;
}