@import '../../../../../libs/lumx/dist/scss/bourbon.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_mixins.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../../../libs/lumx/dist/scss/modules/_icon.scss';

.picture-name-input__container {
    position: relative;
}

.picture-name-input__input-wrapper {
    position: relative;

    &:hover {
        .picture-name-input__clear {
            opacity: 1;
        }
    }
}

.picture-name-input__buttons-wrapper {
    margin-left: 8px;
}

.picture-name-input__clear {
    display: block;
    position: absolute;
    right: -8px;
    bottom: 8px;
    cursor: pointer;
    line-height: $base-spacing-unit * 4;
    @include transition-property(opacity, color);
    @include transition-duration(.2s);

    &:hover {
        color: $red-500;
    }

    z-index: 99;
}