.taxon-search-form {
  .taxon-search-form__query {
    max-width: 800px;
    margin: auto;
  }
}
.taxon-search-form__toolbar {
  height: 39px;
}
.taxon-search-form__txref-filter {
  width: 330px;
}