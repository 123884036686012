
.signin-container {
  display: flex;
  min-height: 577px!important;

  &__form {
    width: 550px;
    height: 100%;
  }

  &__brand {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo img {
      width: 200px;
    }

    &__name {
      font-size: 20px;
    }
  }
}
