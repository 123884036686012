@import '../../variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__item {
    display: inline-block;
    .breadcrumb-label {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90px;
      @include media-query(desk-wide) {
        max-width: 160px;
      }
    }
    &:last-child:not(:first-child),
    &_3_4 {
      .breadcrumb-label {
        max-width: 140px;
        @include media-query(desk-wide) {
          max-width: 160px;
        }
      }
    }
    &_1_1,
    &_1_2,
    &_2_2 {
      .breadcrumb-label {
        max-width: none !important;
      }
    }
  }

  .toolbar__right {
    .btn {
      padding: 0;
      min-width: 38px;
    }
  }

  &__logo {
    height: 62px;
    display: inline-block;
  }

  &__profile {
    line-height: 30px !important;
  }

  .btn-location {
    .label {
      font-size: 14px;
      &:not(:empty) {
        padding-right: 6px;
      }
    }
  }
}

.load-animate {
  animation: load-animate 2s infinite linear;
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.dialog-tracking-conf {
  .dialog__content {
    min-height: calc(100vh - 80px);
  }
  .cols {
    display: flex;
  }
  .col-studies {
    flex: 1;
  }
  .col-params {
    flex: 1;
  }
  .form-item-interval,
  .form-item-maxstandby {
    .text-field {
      &:not(.text-field--is-focused) {
        .text-field__label {
          color: #000;
        }
      }
      &.text-field--is-active {
        .text-field__label {
          font-weight: bold;
        }
      }
    }
  }
}
