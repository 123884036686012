@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import '../../../base/mixins.scss';

.field-record--on-the-fly {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 12px;
  pointer-events: none;
  z-index: 1000;

  &__container {
    padding-bottom: $field-record-on-the-fly-buttons-height * 1.5;
  }

  &__btn {
    pointer-events: auto;
    padding: 3px !important;
  }

  &__container__margin {
    margin-bottom: $field-record-on-the-fly-buttons-height * 1.5;
  }
}

.tx-group-dropdown {
  min-width: 250px !important;
}
