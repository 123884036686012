@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_mixins.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import '../../../../../libs/lumx/dist/scss/base/_typography.scss';

.study-field-surveys-container__bottom__message {
  @include typography('body-1');

  text-align: left;
}