@import "../../../base/variables.scss";
@import "../../../../../libs/lumx/dist/scss/tools/_responsive.scss";
@import "../../../../../libs/lumx/dist/scss/settings/_colors.scss";
@import "../../../../../libs/lumx/dist/scss/settings/_defaults.scss";
@import "../../../../../libs/lumx/dist/scss/tools/_mixins.scss";

.field_record__highlight {
  background-color: $green-300;
}

$field-survey-details-header-height: 0px;
$field-survey-details-header-height-palm: 40px;
$row0-height: 48px;
$row1-height: 40px;
$row1-height-palm: 80px;
$row2-height: 64px;
$row3-height: 32px;
$field-survey-header-rp-height: $field-survey-details-header-height + $row0-height + $row1-height + $row2-height + $row3-height;
$field-survey-header-rp-height-palm: $field-survey-details-header-height-palm + $row0-height + $row1-height-palm + $row2-height + $row3-height;

.field-survey-header-rp {
  position: fixed;
  z-index: 98;
  max-width: 100%;
  width: $desk-width;
}

.field-survey-field-records-rp-form-element {
  border-left: 5px solid $grey-300;
}

.field-survey-header-rp ~ .data-table-container {
  margin-top: $field-survey-header-rp-height;

  @include media-query(palm) {
    margin-top: $field-survey-header-rp-height-palm;
  }
}

.field-survey-details-rp-sync-alert-message {
  margin: 0 auto;
}

.field-survey-details-header-rp {
  height: $field-survey-details-header-height;

  @include media-query(palm) {
    padding-top: 8px;
    padding-bottom: 8px;
    height: $field-survey-details-header-height-palm;
  }
}

.row0 {
  height: $row0-height;
}

.row1 {
  height: $row1-height;

  @include media-query(palm) {
    height: $row1-height-palm;
  }
}

.row2 {
  height: $row2-height;
}

.row3 {
  height: $row3-height;
}

.data-table.field-record-rp-data-table {
  border-left: 8px solid grey;

  &-STRATE_ARBORESCENTE {
    border-left-color: $green-800;
  }

  &-STRATE_ARBUSTIVE {
    border-left-color: $green-300;
  }

  &-STRATE_HERBACEE {
    border-left-color: $yellow-400;
  }

  &-STRATE_BRYO_LICHENIQUE {
    border-left-color: $purple-300;
  }

  th, td {
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;
    border-bottom: 1px solid #e0e0e0;

    &:first-child {
      padding-left: ($base-spacing-unit * 2) !important;
      padding-right: $base-spacing-unit;
    }

    &:last-child {
      padding-left: $base-spacing-unit;
      padding-right: $base-spacing-unit;
    }
  }

  th {
    height: 40px;

    @include font-size(12px);

    font-weight: 400;
    color: $black-2;
    text-align: left;
    white-space: nowrap;

    i {
      margin-right: $base-spacing-unit / 2;
    }
  }

  td {
    height: 40px;

    @include font-size(13px);

    padding-top: 0;
    padding-bottom: 0;
  }

  tr:last-child td {
    border-bottom: none;
  }
}

.field-record-rp-form-header-column {
  font-size: 0.8rem;

  .row1 {
    line-height: $row1-height;
  }

  @include media-query(palm) {
    display: none;
  }
}
