@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';

.study-map {
  height: 100vh;

  @include media-query(portable) {
    padding-top: $base-spacing-unit * 7;
  }

  @include media-query(desk) {
    padding-top: $base-spacing-unit * 8;
  }
}
