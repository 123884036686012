.slider-pourcent {
  width: 165px;
}
.slider-pourcent__value {
  display: inline-block;
  vertical-align: text-bottom;
  width: 30px
}
.w-strate-680 {
   width: 680px;
}
