@import "../../../base/variables.scss";
@import "../../../../../libs/lumx/dist/scss/tools/_responsive.scss";
@import "../field-survey-field-records-rp/field-survey-field-records-rp.scss";

.mtb {
  margin-top: auto;
  margin-bottom: auto;
}

.field-record-rp-form-title {
  margin-right: 5px;
  flex: 0 0 94px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 94px;

  @include media-query(palm) {
    flex: 0 1 130px !important;
    max-width: 130px !important;
  }
}

.field-record-rp-form-title {
  font-size: 0.8rem;
  line-height: $row1-height;
}
.field-record-rp-form-cf-label {
  font-size: 0.8rem;
}

.field-record-rp-form-title:first-letter {
  text-transform: capitalize;
}

.row3 .flex-lap > [flex-item] {
  @include media-query(palm) {
    padding: 0 1px !important;
  }
}

.row2 .flex-lap > [flex-item] .btn--xs,
.row3 .flex-lap > [flex-item] .btn--xs {
  @include media-query(palm) {
    padding-left: 8px;
    padding-right: 8px;
  }
}
