@import "../../../../../libs/lumx/dist/scss/settings/_colors.scss";
@import "../../../../../libs/lumx/dist/scss/bourbon.scss";

.field_record__list__ghost {
  color: $grey-500;
  background-color: $grey-100;
}

.grid-pagination {
  position: fixed;
  z-index: 3;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  padding: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field-record-details--on-the-fly__container {
  .data-table-container {
    margin-bottom: 50px;
  }
  .grid-pagination {
    bottom: 89px;
    border-bottom: 1px solid #e0e0e0;
  }
}

.dropdown-pagination {
  .btn {
    font-size: 16px;
  }
}

.dropdown-menu-pagination {
  max-height: 315px;
}