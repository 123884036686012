@import "../../../../../libs/lumx/dist/scss/settings/_colors.scss";
@import "../../../../../libs/lumx/dist/scss/bourbon.scss";

.field-records-vignette {
  &__item {
    flex: 0 0 250px !important;
    max-width: 250px;
    cursor: pointer;

    &__header {
      flex: 0 0 90px;
      height: 90px;

      &__name {
        font-weight: bold;
      }
    }

    &__footer {
      flex: 0 0 32px;
      height: 32px;

      &__headcount {
        flex: 0 1 40px !important;
      }

      &__button {
        flex: 0 1 34px !important;
      }
    }
  }

  &__ghost {
    color: $grey-500;
    background-color: $grey-100;
  }
}
