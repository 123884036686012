@import '../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import './variables.scss';
@import '../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import './mixins.scss';

@import './flex-lap.scss';
@import './condensed-data-table.scss';

.overflow-scroll {
  overflow-y: auto !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}

a {
  color: inherit;
}

.view-container {
  display: block;
}

.view-container > div {
  padding-top: $header-height;
  height: 100vh;

  @include media-query(portable) {
    padding-top: $header-height-portable;
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pt0.lx-select.lx-select--no-floating-label {
  padding-top: 0px !important;
}

.lx-select.lx-select--no-floating-label {
  padding-top: 8px !important;
}

.visible-inline-lg {
  display: none;

  @include media-query(desk) {
    display: inline;
  }
}

@media not screen and (min-width: 1280px) {
  .visible-desk-wide {
    display: none !important;
  }
}

.hide-up-to-portable {
  @include media-query(portable) {
    display: none;
  }
}

.hide-from-portable {
  @include media-query(desk) {
    display: none !important;
  }
}

.hide-up-to-palm {
  @include media-query(lap-and-up) {
    display: none;
  }
}

.hide-from-palm {
  @include media-query(palm) {
    display: none !important;
  }
}

.form-item {
  width: 400px;

  @include media-query(palm) {
    width: 100%;
    margin: 0 auto;
  }

  @include media-query(lap) {
    width: 400px;
    margin: 0 auto;
  }
}

.form-content {
  width: $desk-width;
  margin: auto;

  @include media-query(palm) {
    width: 100%;
  }

  @include media-query(lap) {
    width: 100%;
  }
}

.h-100pc {
  height: 100%;
}

.w-100pc {
  width: 100%;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-135px {
  width: 136px;
}

.w-150px {
  width: 150px;
}

.w-200px {
  width: 200px;
}

.input-invisible {
  border: none;
}

.align-self-center {
  align-self: center;
}

.data-table__link-cell {
  &:hover {
    background-color: $grey-300;
  }

  cursor: pointer;
}

.card__link-cell {
  &:hover {
    background-color: $grey-300;
  }

  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bt-grey-500 {
  border-top: 1px solid $grey-100;
}

.bt-grey-300 {
  border-top: 1px solid $grey-300;
}

.bb-grey-500 {
  border-bottom: 1px solid $grey-300;
}

.bb-none {
  border-bottom: 0px !important;
}

.flex-0-1-auto {
  flex: 0 1 auto !important;
}

.flex-push-last {
  @include media-query(palm) {
    width: 100%;
    order: 1;
  }
}

.h-100px {
  @include media-query(desk) {
    height: 100px;
  }

  &.flex-lap {
    @include media-query(lap-and-up) {
      height: 100px;
    }
  }
}

.text-nouppercase .btn {
  text-transform: none !important;
}

.mdi-30px {
  font-size: 30px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.height-auto {
  height: auto !important;
}

.lh-44 {
  line-height: 44px !important;
  min-height: 44px !important;
}

.btn.btn--icon.btn-36 {
  height: 36px !important;
  width: 36px !important;
}

.lh-36 {
  line-height: 36px !important;
  min-height: 36px !important;
}

.tab-icon {
  vertical-align: baseline !important;
  font-size: 1rem !important;
  line-height: 36px !important;
  margin-right: 5px;
}

.w-600 {
  width: 600px;
}

.td__no-border {
  border: none !important;
}

.display-inline-block {
  display: inline-block !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.small-label {
  font-size: 12px;
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 1px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

[required] .lx-select__floating-label,
[required] .text-field__label,
[required] .small-label,
[required] .lx-select__selected--placeholder,
.mandatory-field {
  font-weight: bold !important;
}

.mdi-20px {
  font-size: 20px !important;
}

.mdi-30px {
  font-size: 30px !important;
}

.dialog--m {
  @include media-query(desk) {
    width: 500px;
    margin-left: -250px;
  }

  @include media-query(portable) {
    width: 60%;
    margin-left: -30%;
  }
}

.select-taxon {
  margin-left: 0 !important;
  left: 10px !important;
  right: 10px !important;
  width: auto !important;
  .lx-select__choice {
    @include display(flex);
    @include flex-direction(row);
    @include align-items(center);
    height: 32px;
    cursor: pointer;
    @include font-size(14px);
    color: $black-1;
    line-height: 32px;
    text-decoration: none;
    white-space: nowrap;
    // On reprend .dropdown-link sans le padding, on le met sur les sous-éléments
    padding: 0;
    & > span {
      display: flex;
    }
    & > .taxon-principal,
    & > .taxon-secondaire {
      padding: 0 $base-spacing-unit * 4 0 $base-spacing-unit * 2;
      flex-grow: 1;
    }
  }
  // Gestion des groupes de résultats (.dropdown-link--is-header)
  [ng-repeat-start] {
    padding: 0 $base-spacing-unit * 4 0 $base-spacing-unit * 2;
    color: $black-3;
    cursor: default;
  }
}
.position-absolute {
  position: absolute !important;
}

.header-favoris,
.header-favoris .dropdown-menu__content {
  padding-top: 0;
  padding-bottom: 0;
}

.header-favoris ul {
  padding-top: 5px;
  padding-bottom: 5px;
}

.favoris-persistent {
  background: #f3f3f3;
}

.favoris-persistent a:hover {
  background: #ccc !important;
}

.smart-separator {
  &:first-child,
  &:last-child {
    display: none;
  }
}
