@import '../../../../../node_modules/leaflet/dist/leaflet.css';
@import '../../../../../node_modules/leaflet-draw/dist/leaflet.draw.css';
@import '../../../../../node_modules/mdi/css/materialdesignicons.css';

.map-container {
  height: 100%;
  width: 100%;
}
.map-container__geolocalize {
  height: 36px;
  width: 36px;
  background-color: #FFFFFF;
  cursor: pointer;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  &:hover {
    background-color: darken(#FFFFFF, 10%);
  }
}
.leaflet-bottom.leaflet-left,
.leaflet-bottom.leaflet-right {
  // On passe en dessous de la barre de saisie rapide
  z-index: 990;
}
.leaflet-pane.leaflet-overlay-pane {
  // Les géométries ponctuelles sans marker sont dans overlay-pane (au lieu de data-pane),
  // il faut qu'on passe au dessus des couckes mbtiles et KML persos (mbtiles-pane et kml-pane à z-index: 400)
  z-index: 401;
}

.leaflet-right .leaflet-control {
  margin-top: 4px;
}

.leaflet-top .leaflet-control {
  margin-top: 4px;
}

.leaflet-left .leaflet-control {
  margin-left: 4px;
}

.map-container__custom-control .leaflet-bar-part,
.map-container__custom-control .leaflet-bar-part:hover,
.leaflet-control-layers .leaflet-control-layers-toggle,
.leaflet-control-locate .leaflet-bar-part,
.leaflet-control-locate .leaflet-bar-part:hover {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;

  .leaflet-touch & {
    height: 36px;
    width: 36px;
    line-height: 36px;
  }
}

/* Fix bug caused by normalize.css > img max-width 100% */
.leaflet-tile-container img {
  max-width: none !important;
}

.map-container__custom-control {
  a {
    font-size: 1.4em;
    color: #444;
  }
}

.map-container__popup-link {
  display: flex;
  line-height: 1.4em;
  text-decoration: none !important;

  &:before {
    display: inline-block;
    font: normal normal normal 1.4em/1 "Material Design Icons";
    margin-right: 6px;
    content: "\F3F0";
  }
}
.leaflet-marker-icon > .fs-headline {
  font-size: 24px !important;
}

.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}
