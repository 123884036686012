.aside {
  position: fixed;
  top: 0px;
  height: 100%;
  z-index: 2000;

  width: 550px;
  transform: translate3d(-550px, 0, 0);
  transition: 0.4s ease-out transform;
  overflow: hidden;

  &__visible {
    transform: translate3d(0, 0, 0);
  }

  &__close {
    right: 0px;
    top: 0px;
    position: absolute;
    z-index: 21;
  }

  &__content {

    width: 100%;
    height: 100%;
    right: 0px;
    position: absolute;

    &__inputs {
      width: 70%;
      margin-left: 15%;
      margin-top: 50px;

      .btn {
        width: 100%;
        text-align: left;
      }
    }
    &__bottom {
      margin-top: auto;
      &__version {
        text-align: center;
      }
      &__buttons {
        .bottom-button {
          width: 100%;
        }
      }
    }
  }

  &__visible ~ &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1999;
  }
  &__logo_container {
    text-align: center;
  }
  &__logo {
    height: 102px;
    display: inline-block;
  }
}
