@import "../../../base/variables.scss";
@import "../../../../../libs/lumx/dist/scss/tools/_responsive.scss";

$field-survey-header-height: 39px;
$field-survey-details-header-height: 41px;

.field-survey-header {
  position: fixed;
  width: 100%;
  z-index: 99;
  height: $field-survey-header-height;
  top: $header-height;

  @include media-query(portable) {
    top: $header-height-portable;
  }
}

.field-survey-header ~ .field-survey-details-container {
  margin-top: $field-survey-header-height;
}

.field-survey-details-header {
  @include media-query(palm) {
    position: fixed;
    z-index: 98;
    max-width: 100%;
    width: $desk-width;
  }
}

.field-survey-details-header ~ .data-table-container {
  @include media-query(palm) {
    margin-top: $field-survey-details-header-height;
  }
}

@include media-query(palm) {
  height: $field-survey-details-header-height;

  .field-survey-details-header .field-survey-details {}
}
