@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import '../../../base/mixins.scss';

$surveytype-container-height: 32px;
$inputs-container-height: 44px;
$field-record-details-padding: 6px;

.field-record-details--on-the-fly {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 995;
  padding: $field-record-details-padding;

  .flex-item-18pc {
    flex: 0 0 18%;
    max-width: 18%;

    @include media-query(palm) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      text-align: end;
    }
  }

  .container {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
  }

  .surveytype-radio-spacer {
    @include media-query(palm) {
      flex: 1 1 100%;
    }
  }

  .surveytype-container {
    height: $surveytype-container-height;

    @include media-query(palm) {
      height: $surveytype-container-height * 2;
    }
  }

  .inputs-container {
    height: $inputs-container-height;

    @include media-query(palm) {
      height: $inputs-container-height * 3;
    }


    .taxon-input {
      @include media-query(palm) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }

    .headcount-input {
      @include media-query(palm) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
    }
  }

  .push-right {
    margin-left: auto;
  }

  .surveytype-radio-button--on-the-fly {
    display: inline-block;
    margin-right: 12px;
    flex: 0 0 auto;
    height: $surveytype-container-height;
    line-height: $surveytype-container-height;
    cursor: pointer;
  }

  .extra-data-toggle {
    margin-left: auto;
  }

  &__container {
    padding-bottom: $surveytype-container-height + $inputs-container-height + $field-record-details-padding * 2 + 8px;

    @include media-query(palm) {
      padding-bottom: $surveytype-container-height + $inputs-container-height * 2 + $field-record-details-padding * 2 + 8px;
    }


    @include media-query(lap) {
      padding-bottom: $surveytype-container-height + $inputs-container-height + $field-record-details-padding * 2 + 8px;
    }
  }
}

.field-record--on-the-fly--detail__btn {
  pointer-events: auto;
  padding: 3px !important;
}
